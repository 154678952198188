import { NoSymbolIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { Button } from "../../_ui/Button";

export const NotFound = () => {
  const navigate = useNavigate()

  return (
    <div className={'flex w-full h-full items-center justify-center mt-10'}>
      <div className={'flex flex-col items-center text-center mb-32 px-8'}>
        <NoSymbolIcon className={'w-32 h-32 text-gray-200'} />
        <p className={'text-4xl text-gray-400 font-bold mb-2'}>
          Fehler 404
        </p>
        <p className={'text-gray-400 mb-6'}>
          Die Seite konnte nicht gefunden werden.
        </p>
        <Button onClick={() => navigate('/dashboard')}>Zurück zum Dashboard</Button>
      </div>
    </div>
  )
}
