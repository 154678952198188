import Bugsnag from "@bugsnag/js";
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, UseQueryArgs } from 'urql';
import { Member } from "../../../gql/graphql";
import { Login } from "../../_features/Login";
import { PopupContent } from "../../_features/PopupContent";
import { useServiceWorker } from "../../_features/ServiceWorker";
import { useSnackbarPush } from "../../_features/SnackbarContext/SnackbarContext";
import { SwUpdateDialog } from "../../_features/SwUpdateDialog";
import { cacheDatabaseName } from "../../_lib/graphql/exchanges/cacheExchange";
import { loginUserMutation, logoutUserMutation } from "../../_lib/graphql/mutations";
import { readMyselfQuery } from '../../_lib/graphql/queries';
import { Spinner } from "../../_ui";

interface MyselfContextType extends Member {
  IsLoggedIn?: boolean,
  login: (email: string, password: string, rememberMe: boolean) => void
  logout?: () => void,
  canAccess: (code: string | string[]) => boolean
  refetchUser: () => void
  isDebugUser?: boolean
}

const MyselfContext = createContext<MyselfContextType | null>(null);

export const useMyself = () => useContext(MyselfContext)

type PropTypes = {
  children: React.ReactNode
  ignoreSubscription?: boolean
}

const UserContextProvider = ({ children, ignoreSubscription }: PropTypes) => {
  const { isUpdateAvailable, updateAssets } = useServiceWorker()
  const [hasData, setHasData] = useState(true)
  const isFirstLoading = useRef(true)
  const { pushSuccess, pushError } = useSnackbarPush()

  const [myself, refetchUser] = useQuery({ query: readMyselfQuery } as UseQueryArgs);
  const [loginState, executeLoginUser] = useMutation(loginUserMutation)
  const [logoutState, executeLogoutUser] = useMutation(logoutUserMutation)

  useEffect(() => {
    if (!isFirstLoading.current) {
      setHasData(myself.data !== undefined && myself.data?.readMyself)
    } else {
      isFirstLoading.current = false
    }
  }, [myself])

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      if (myself.data?.readMyself?.id) {
        Bugsnag.setUser(myself.data?.readMyself?.id);
      } else {
        Bugsnag.setUser();
      }
    }
  }, [myself])

  useEffect(() => {
    const isPWA = window.matchMedia('(display-mode: standalone)').matches ||
      (window.navigator as any)?.navigator?.standalone === true ||
      document.referrer.includes('android-app://')

    if (process.env.NODE_ENV !== 'development') {
      Bugsnag.addMetadata('environment', {
        isPWA: isPWA ? 'true' : 'false',
      });
    }
  }, [])

  const login = useCallback((email: string, password: string, rememberMe: boolean) => {
    executeLoginUser({
      input: {
        email,
        password,
        rememberMe
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
      } else {
        localStorage.setItem('token', res.data?.loginUser?.access_token || '')
        localStorage.setItem('refresh_token', res.data?.loginUser?.refresh_token || '')

        const time = Math.round(new Date().getTime() / 1000)
        const expiredTimestamp = time + parseInt(res.data?.loginUser?.expiry || '', 10)
        localStorage.setItem('expiredTimestamp', expiredTimestamp.toString());
        pushSuccess('Login erfolgreich')
        refetchUser()
      }
    })
  }, [executeLoginUser, refetchUser, pushError, pushSuccess])

  const logout = useCallback(() => {
    executeLogoutUser({}).finally(() => {
      indexedDB.deleteDatabase(cacheDatabaseName)
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('expiredTimestamp')
      refetchUser()
    })
  }, [executeLogoutUser, refetchUser])

  useEffect(() => {
    const listener = () => {
      logout()
    }

    document.addEventListener('logoutUser', listener)
    return () => document.removeEventListener('logoutUser', listener)
  }, [logout])

  const canAccess = useCallback((code: string | string[]) => {
    const currentMember = myself.data?.readMyself
    if (currentMember && currentMember.permissions) {
      if (currentMember.permissions.findIndex((permission: string) => permission && permission === 'ADMIN') >= 0) {
        return true
      }

      return typeof code === 'object'
        ? currentMember.permissions.findIndex((permission: string) => permission && code.includes(permission)) >= 0
        : currentMember.permissions.findIndex((permission: string) => permission && permission === code) >= 0
    }
    return false
  }, [myself])

  useEffect(() => {
    const handleLogout = () => {
      setHasData(false)
    }

    document.addEventListener("loginError", () => handleLogout(), false)
  }, [])

  return (
    <MyselfContext.Provider
      value={{
        ...myself.data?.readMyself,
        IsLoggedIn: hasData,
        login,
        logout,
        canAccess,
        refetchUser
      }}
    >
      {/* TODO: new login handling */}
      {hasData
        ? (
          <>
            {children}
            {myself.data?.readMyself?.requirePopupConfirmation && (
              <PopupContent />
            )}
          </>
        ) : <Login />
      }
      {(myself.stale || myself.fetching || logoutState.fetching || loginState.fetching) && (
        <div className={'fixed top-0 w-screen h-full z-40 backdrop-blur-sm bg-black/30'}>
          <div className={'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'}>
            <Spinner size={'w-16 h-16'} />
          </div>
        </div>
      )}
      {isUpdateAvailable && updateAssets && (
        <SwUpdateDialog />
      )}
    </MyselfContext.Provider>
  );
}

export default UserContextProvider;
