import { createClient, fetchExchange, mapExchange } from '@urql/core'
import { getIntrospectedSchema, minifyIntrospectionQuery } from '@urql/introspection'
import { refocusExchange } from '@urql/exchange-refocus'
import { getIntrospectionQuery } from 'graphql'
import cacheExchange from './exchanges/cacheExchange'
import { GRAPHQL_ENDPOINT } from '../../_constants'
import authExchange from './exchanges/authExchange'
import versionJson from '../../_constants/version.json'

fetch(GRAPHQL_ENDPOINT, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: 'include',
  body: JSON.stringify({
    variables: {},
    query: getIntrospectionQuery({ descriptions: false }),
  }),
})
  .then(result => result.json())
  .then(({ data }) => {
    const minified = minifyIntrospectionQuery(getIntrospectedSchema(data));
    window.localStorage.setItem('schema', JSON.stringify(minified));
  });

/**
 * URQL Client for silverstripe
 *
 * @returns {*}
 */
const GraphQLClientSilverstripe = createClient({
  url: GRAPHQL_ENDPOINT,
  exchanges: [
    cacheExchange,
    refocusExchange(),
    authExchange,
    mapExchange({
      onError(error, _operation) {
        const isAuthError = error.graphQLErrors.some(e => e.message.includes('login erforderlich'))
          || error.graphQLErrors.some(e => e.message.includes('Bitte melde dich an, um fortzufahren'))
          || error.graphQLErrors.some(e => e.message.includes('The resource owner or authorization server denied the request'))
          || error.graphQLErrors.some(e => e.message.includes('Invalid token'));
        if (isAuthError) {
          const event = new CustomEvent("loginError");
          document.dispatchEvent(event)
        }
      },
    }),
    fetchExchange
  ],
  requestPolicy: 'cache-and-network',
  fetchOptions: () => {
    const isPwa = window.matchMedia('(display-mode: standalone)').matches ||
      window.navigator?.navigator?.standalone === true ||
      document.referrer.includes('android-app://')

    return {
      headers: {
        "x-socket-id": sessionStorage.getItem('socket-id'),
        "x-pwa": isPwa,
        "x-version": versionJson.version,
      },
      credentials: 'include'
    }
  },
});

export default GraphQLClientSilverstripe;
