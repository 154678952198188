import { graphql } from "../../../gql";


export const readMyselfQuery = graphql(`
  query ReadMyself {
    readMyself {
      id
      firstName
      surname
      email
      gender
      profileImageUrl
      boxOwnership {
        id
        displayTitle
        hasActiveSubscription
      }
      boxOperator {
        id
        displayTitle
        hasActiveSubscription
        requestedMembersAmount
      }
      hasActiveSubscription
      hasBoxMembership
      hasPendingBoxMembership
      boxMemberships {
        id
        displayTitle
      }
      permissions
      dashboardBadgeCount
      streak {
        id
        numberOfDays
        streakWeeks
        upcomingStreakDays
        upcomingStreakWeeks
      }
      requirePopupConfirmation
    }
  }
`);

export const readExercisesQuery = graphql(`
  query ReadExercises {
    readExercises {
      id
      title
      bestResult
      lastTraining
      type
    }
  }
`);

export const readOneExerciseQuery = graphql(`
  query ReadOneExercise($input: ReadOneExerciseInput!) {
    readOneExercise(input: $input) {
      id
      title
      bestResult
      lastTraining
      type
      exerciseResults {
        ...ExerciseResultFragment
      }
    }
  }
`);

export const readOneExerciseDetailsQuery = graphql(`
  query ReadOneExerciseDetails($input: ReadOneExerciseInput!) {
    readOneExercise(input: $input) {
      id
      title
      type
      optionalEquipment
    }
  }
`);

export const readOneExerciseResultQuery = graphql(`
  query ReadOneExerciseResult($input: ReadOneExerciseResultInput!) {
    readOneExerciseResult(input: $input) {
      ...ExerciseResultFragment
    }
  }
`);

export const readWorkoutExecutionsByDateQuery = graphql(`
  query ReadWorkoutExecutionsByDate($input: ReadWorkoutExecutionsByDateInput!) {
    readWorkoutExecutionsByDate(input: $input) {
      id
      public {
        id
        workout {
          ...PublicWorkoutFragment
        }
      }
      private {
        id
        workout {
          ...PrivateWorkoutFragment
        }
      }
    }
  }
`);

export const readOneWorkoutQuery = graphql(`
  query ReadOneWorkout($input: ReadOneWorkoutInput!) {
    readOneWorkout(input: $input) {
      ...PublicWorkoutFragment
    }
  }
`);

export const readOneWorkoutExecutionQuery = graphql(`
  query ReadOneWorkoutExecution($input: ReadOneWorkoutExecutionInput!) {
    readOneWorkoutExecution(input: $input) {
      id
      date
      workout {
        ...PublicWorkoutFragment
      }
      teammates {
        id
        name
        adoptionToken
        editable
        isMyself
        exerciseResults {
          ...ExerciseResultFragment
        }
      }
    }
  }
`);

// TODO: Implement this query
export const readOneWorkoutExecutionRankingQuery = graphql(`
  query ReadOneWorkoutExecutionRanking($input: ReadOneWorkoutExecutionInput!) {
    readOneWorkoutExecution(input: $input) {
      id
      date
      workout {
        id
        title
        workoutType
      }
      boxRanking {
        position
        member {
          id
          firstName
          surname
          profileImageUrl
        }
        athlet {
          id
          exerciseResults {
            ...ExerciseResultFragment
          }
        }
        score
      }
      skillRanking {
        id
        preset {
          id
          presetType
          presetValue
          variant
          exercise {
            id
            title
          }
        }
        ranking {
          position
          member {
            id
            firstName
            surname
            profileImageUrl
          }
          score
        }
      }
    }
  }
`);

export const readOneAthleteQuery = graphql(`
  query ReadOneAthlet($input: ReadOneAthletInput!) {
    readOneAthlet(input: $input) {
      id
      name
      adoptionToken
      editable
      exerciseResults {
        ...ExerciseResultFragment
      }
    }
  }
`)

export const readCustomerQuery = graphql(`
  query ReadCustomer {
    readCustomer {
      id
      displayTitle
      description
    }
  }
`);

export const readBoxOwnershipQuery = graphql(`
  query ReadBoxOwnership {
    readBoxOwnership {
      id
      displayTitle
      hasActiveSubscription
      confirmedMembers {
        id
        firstName
        surname
        profileImageUrl
        latestActivity
        latestBoxActivity
      }
      requestedMembers {
        id
        firstName
        surname
        profileImageUrl
        latestActivity
        latestBoxActivity
      }
    }
  }
`);

export const readPricesQuery = graphql(`
  query ReadPrices {
    readPrices {
      id
      amount
      period
      title
      description
    }
  }
`);

export const readContentQuery = graphql(`
  query ReadContent($type: String!) {
    readContent(type: $type)
  }
`);

export const readFaqItemsQuery = graphql(`
  query ReadFaqItems {
    readFaqItems {
      id
      title
      content
    }
  }
`);

export const readWorkoutClassesByDateQuery = graphql(`
  query ReadWorkoutClassesByDate($input: ReadWorkoutClassesByDateInput!) {
    readWorkoutClassesByDate(input: $input) {
      id
      title
      date
    }
  }
`);

export const readOneWorkoutClassQuery = graphql(`
  query ReadOneWorkoutClass($input: ReadOneWorkoutClassInput!) {
    readOneWorkoutClass(input: $input) {
      id
      title
      date
      workoutExecutions {
        id
        workout {
          id
          title
          workoutType
          allowExerciseTracking
          measurementExercise {
            id
            title
          }
          measurementType
          workoutBlocks {
            ...WorkoutBlockFragment
          }
        }
      }
      potentialAthletes {
        id
        firstName
        surname
      }
      classAthletes {
        id
        name
        member {
          id
          firstName
          surname
          profileImageUrl
        }
        memberID
        workoutExecutionID
        exerciseResults {
          ...ExerciseResultFragment
        }
      }
    }
  }
`);

export const readExistingResultsByWeekQuery = graphql(`
  query ReadExistingResultsByWeek($input: ReadExistingResultsByWeekInput!) {
    readExistingResultsByWeek(input: $input) {
      date
      hasResult
    }
  }
`)

export const readBlogEntriesQuery = graphql(`
  query ReadBlogEntries {
    readBlogEntries {
      id
      creator {
        id
        firstName
        surname
        profileImageUrl
      }
      title
      content
      date
      isNew
    }
  }
`)

export const readBoxWorkoutsQuery = graphql(`
  query ReadBoxWorkouts($input: ReadOneCustomerInput!) {
    readOneCustomer(input: $input) {
      id
      title
      boxWorkouts {
        id
        title
        description
        workoutType
      }
    }
  }
`)

export const readPersonalWorkoutsQuery = graphql(`
  query ReadPersonalWorkouts {
    readMyself {
      id
      personalWorkouts {
        id
        title
        description
        workoutType
      }
    }
  }
`)
