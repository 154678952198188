import { offlineExchange } from '@urql/exchange-graphcache'
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage'
import {
  readMyselfQuery,
  readOneExerciseQuery,
  readWorkoutClassesByDateQuery,
  readWorkoutExecutionsByDateQuery
} from '../queries'

export const cacheDatabaseName = 'track-the-rep-cache'

const storage = makeDefaultStorage({
  idbName: cacheDatabaseName,
  maxAge: 30,
});

const cache = offlineExchange({
  schema: JSON.parse(window.localStorage.getItem('schema')),
  keys: {
    ExistingResultDate: item => item.date,
    BoxRankingItem: _ => null,
  },
  resolvers: {
    Query: {
      readOneWorkout: (parent, args, cache) => {
        if (args.input.id) {
          return {
            __typename: 'Workout',
            id: args.input.id,
          }
        } else if (args.input.workoutExecutionID) {
          return cache.resolve('Query', 'readOneWorkout', args)
            || cache.resolve(`WorkoutExecution:${args.input.workoutExecutionID}`, 'workout')
        }
      },
      readOneWorkoutExecution: (parent, args, cache) => ({
        __typename: 'WorkoutExecution',
        id: args.input.id,
      }),
      readOneExercise: (parent, args, cache) => ({
        __typename: 'Exercise',
        id: args.input.id,
      }),
      readOneWorkoutClass: (parent, args, cache) => ({
        __typename: 'WorkoutClass',
        id: args.input.id,
      }),
      readOneAthlet: (parent, args, cache) => ({
        __typename: 'Athlet',
        id: args.input.id,
      }),
      readOneExerciseResult: (parent, args, cache) => ({
        __typename: 'ExerciseResult',
        id: args.input.id,
      }),
    },
    Member: {
      permissions(parent, args, cache, info) {
        return parent.permissions?.split(',') || []
      },
    },
  },
  optimistic: {
    startWorkoutClass: (variables, cache, info) => {
      const existingData = cache.readQuery({ query: readWorkoutClassesByDateQuery, variables: { input: { date: variables.input.date } }})?.readWorkoutClassesByDate || []
      return [
        ...existingData,
        {
          __typename: 'WorkoutClass',
          id: 'unknown',
          date: (new Date()).toISOString().slice(0, 10),
          title: variables.input.title
        }
      ]
    },
    addResult: (variables, cache, info) => {
      return {
        __typename: 'ExerciseResultAndAthlet',
        athlet: variables.input.athletID ? {
            __typename: 'Athlet',
            id: variables.input.athletID,
            exerciseResults: [
              ...(cache.resolve(`Athlet:${variables.input.athletID}`, 'exerciseResults') || []),
              {
                __typename: 'ExerciseResult',
                id: variables.input.exerciseResultID || 'unknown',
                ...variables.input,
                date: (new Date()).toISOString().slice(0, 10),
              }]
        } : null,
        exerciseResult: {
          __typename: 'ExerciseResult',
          id: variables.input.exerciseResultID || 'unknown',
          ...variables.input,
          date: (new Date()).toISOString().slice(0, 10),
        }
      }
    }
  },
  updates: {
    Mutation: {
      addResult(result, args, cache, _info) {
        if (args.input.exerciseID) {
          cache.updateQuery({
            query: readOneExerciseQuery,
            variables: { input: { id: args.input.exerciseID } }
          }, data => {
            if (!data || args.input.exerciseResultID) {
              return data
            }

            return {
              readOneExercise: {
                ...data.readOneExercise,
                exerciseResults: [
                  ...((result?.addResult?.exerciseResult) ? [result.addResult.exerciseResult] : []),
                  ...(data.readOneExercise?.exerciseResults || []),
                ]
              }
            }
          });
        }
      },
      addWorkout(result, args, cache, _info) {
        cache.updateQuery({ query: readWorkoutExecutionsByDateQuery, variables: { input: { date: args.input.date }} }, data => {
          return {
            readWorkoutExecutionsByDate: {
              ...result.addWorkout
            }
          }
        });
      },
      deleteWorkout(result, args, cache, _info) {
        cache.updateQuery({ query: readWorkoutExecutionsByDateQuery, variables: { input: { date: args.input.date }} }, data => {
          return {
            readWorkoutExecutionsByDate: {
              ...result.deleteWorkout
            }
          }
        });
      },
      startWorkoutClass(result, args, cache, _info) {
        cache.updateQuery({ query: readWorkoutClassesByDateQuery, variables: { input: { date: args.input.date }} }, data => {
          return {
            readWorkoutClassesByDate: {
              ...result.startWorkoutClass
            }
          }
        });
      },
      markBlogEntryAsRead(result, args, cache, _info) {
        cache.updateQuery({ query: readMyselfQuery }, data => {
          return {
            readMyself: {
              ...data.readMyself,
              dashboardBadgeCount: data.readMyself.dashboardBadgeCount - 1
            }
          }
        })
      }
    }
  },
  storage,
  isOfflineError: (error, result) => {
    return error &&
      (error?.message === '[Network] Load failed'
        || (
          error.networkError &&
          !error.response &&
          ((typeof navigator !== 'undefined' && navigator.onLine === false) ||
            /request failed|failed to fetch|network\s?error/i.test(
              error.networkError.message
            ))
        ))
  }
});

export default cache;
