import { Dialog, Transition } from "@headlessui/react";
import { addDays, format, startOfWeek } from "date-fns";
import React, { Fragment, useCallback, useRef } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useNavigate } from "react-router-dom";
import { useMutation } from "urql";
import { adoptAthletMutation } from "../../_lib/graphql/mutations";
import { useSnackbarPush } from "../SnackbarContext/SnackbarContext";

type PropTypes = {
  isOpen: boolean
  onClose: () => void
}

export const AdoptAthletDialog = ({ isOpen, onClose }: PropTypes) => {
  const navigate = useNavigate()
  const [, executeAdoptAthlet] = useMutation(adoptAthletMutation)
  const { pushSuccess, pushError } = useSnackbarPush()
  const dataRef = useRef<string | null>(null)

  const handleScan = useCallback((token: string | null) => {
    if (!token) {
      return
    }

    dataRef.current = token

    executeAdoptAthlet({
      input: {
        token
      }
    }).then(res => {
      if (res.error) {
        pushError(res.error.message)
        dataRef.current = null
      } else {
        pushSuccess('Ergebnisse importiert')
        onClose()

        const date = res.data?.adoptAthlet?.date || ''
        const start = startOfWeek(new Date(date), { weekStartsOn: 1 })
        const year = addDays(start, 6).toISOString().slice(0, 4)

        navigate(`/calender/${year}/${format(date, "w")}/${new Date(date).getDay()}/${res.data?.adoptAthlet?.id}`)
        dataRef.current = null
      }
    })
  }, [executeAdoptAthlet, pushSuccess, pushError, onClose, navigate])

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[80]" onClose={onClose}>
        {/* Hintergrund Overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur" />
        </Transition.Child>

        {/* Dialog-Fenster */}
        <div className="fixed inset-0 flex items-end justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="transition ease-out duration-300"
            enterFrom="translate-y-full opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="transition ease-in duration-200"
            leaveFrom="translate-y-0 opacity-100"
            leaveTo="translate-y-full opacity-0"
          >
            <div className="fixed inset-0 w-screen overflow-y-auto p-4">
              <div className="flex min-h-full items-center justify-center">
                <Dialog.Panel className="bg-neutral-100/5 backdrop-blur-2xl rounded-lg shadow-lg p-5 max-w-md w-full">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-white pb-2"
                  >
                    Ergebnisse importieren
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="mb-4 text-sm text-neutral-500">
                      Um die Ergebnisse in einen anderen Account zu Übertragen,
                      scanne von dem entsprechend anderen Gerät den untenstehenden QR-Code.
                    </p>

                    <div id={'qr-scanner'} className={'relative w-full aspect-square mb-2 bg-neutral-800 rounded-xl'}>
                      <BarcodeScannerComponent
                        width={'100%'}
                        height={'100%'}
                        onUpdate={(err, result) => {
                          if (result) {
                            handleScan(result.getText())
                          }
                        }}
                        stopStream={!isOpen}
                        facingMode={'environment'}
                        videoConstraints={{
                          facingMode: 'environment'
                        }}
                      />
                      {/*{!fetching && isOpen && (*/}
                      {/*  <QrScanner*/}
                      {/*    className={'w-full h-full bg-red-10 overflow-hidden rounded-xl'}*/}
                      {/*    videoStyle={{objectFit: 'cover'}}*/}
                      {/*    scanDelay={1000}*/}
                      {/*    onResult={handleScan}*/}
                      {/*    constraints={{*/}
                      {/*      facingMode: 'environment'*/}
                      {/*    }}*/}
                      {/*    ViewFinder={() => (*/}
                      {/*      <>*/}
                      {/*        <div*/}
                      {/*          className={'absolute z-10 w-full h-full top-0 left-0 border border-[44px] border-neutral-500/30'}/>*/}
                      {/*        <div className={'absolute z-10 mt-1 top-10 left-10 h-1/4 w-1 bg-neutral-300/40'}/>*/}
                      {/*        <div className={'absolute z-10 mb-1 bottom-10 left-10 h-1/4 w-1 bg-neutral-300/40'}/>*/}
                      {/*        <div className={'absolute z-10 top-10 left-10 w-1/4 h-1 bg-neutral-300/40'}/>*/}
                      {/*        <div className={'absolute z-10 bottom-10 left-10 w-1/4 h-1 bg-neutral-300/40'}/>*/}
                      {/*        <div className={'absolute z-10 mt-1 top-10 right-10 h-1/4 w-1 bg-neutral-300/40'}/>*/}
                      {/*        <div className={'absolute z-10 mb-1 bottom-10 right-10 h-1/4 w-1 bg-neutral-300/40'}/>*/}
                      {/*        <div className={'absolute z-10 top-10 right-10 w-1/4 h-1 bg-neutral-300/40'}/>*/}
                      {/*        <div className={'absolute z-10 bottom-10 right-10 w-1/4 h-1 bg-neutral-300/40'}/>*/}
                      {/*      </>*/}
                      {/*    )}*/}
                      {/*  />*/}
                      {/*)}*/}
                      {/*{fetching && (*/}
                      {/*  <div className={'absolute flex items-center justify-center inset-0 z-20 backdrop-blur'}>*/}
                      {/*    <Spinner size={''}/>*/}
                      {/*  </div>*/}
                      {/*)}*/}
                    </div>
                    {/*<QRCode*/}
                    {/*  bgColor={'transparent'}*/}
                    {/*  fgColor={'#eee'}*/}
                    {/*  value={adoptionToken}*/}
                    {/*  style={{height: "auto", maxWidth: "100%", width: "100%"}}*/}
                    {/*/>*/}
                  </div>
                </Dialog.Panel>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
