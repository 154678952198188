import React from 'react'
import { Dialog } from '../../_ui/Dialog'
import { Button } from '../../_ui/Button'

const Error = () => {
  return (
    <Dialog title={'Ups, das sollte nicht passieren...'} open onClose={() => {}} hideCloseButton>
      <div className={'pt-2'}>
        <p className={'text-sm mb-5'}>
          Versuche die Seite einmal neu zu laden oder komm später wieder.
        </p>
        <div className={'flex gap-2 pt-2'}>
          <Button
            variant={'contained'}
            onClick={() => {
              if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistration().then((registration) => {
                  if (registration && registration.waiting) {
                    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                  }
                });
              }

              window.location.replace('/dashboard')
            }}
          >
            Zurück zum Dashboard
          </Button>

          <Button

            onClick={() => {
              if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistration().then((registration) => {
                  if (registration && registration.waiting) {
                    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                  }
                });
              }

              window.location.reload()
            }}
          >
            Neu laden
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default Error;
