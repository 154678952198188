import { HandThumbUpIcon } from "@heroicons/react/24/outline";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "urql";
import { confirmPopupMutation } from "../../_lib/graphql/mutations";
import { Button } from "../../_ui/Button";
import { Dialog } from "../../_ui/Dialog";

export const PopupContent = () => {
  const navigate = useNavigate()
  const [state, executeConfirmPopup] = useMutation(confirmPopupMutation)

  const handleConfirm = useCallback(() => {
    executeConfirmPopup({}).then(res => {
      if (res.error) {
        console.error(res.error)
      } else {
        navigate('/dashboard')
      }
    })
  }, [executeConfirmPopup, navigate])

  return (
    <Dialog title="Hinweis" open={true} onClose={() => {}} hideCloseButton>
      <p className={'text-sm pb-4'}>
        Die Ansicht der aktuellen Workouts deiner Box findest du ab sofort unter dem Menüpunkt "Kalender".
        Hier kannst du ab sofort auch eigene Workouts erstellen.
      </p>

      <p className={'text-sm pb-4'}>
        Über den Menüpunkt "Workouts" kannst du alle Workouts deiner Box sowie deine persönlichen
        Workouts einsehen. In Kürze wird es dort dann möglich sein, die angelegten Workouts erneut auszuführen
        und entsprechend zu vergleichen.
      </p>

      <Button disabled={state.fetching} onClick={handleConfirm} color={"success"} fullWidth>
        <HandThumbUpIcon className={'w-5 h-5 mr-2'} />
        Verstanden
      </Button>
    </Dialog>
  )
}
