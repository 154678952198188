import React, { useContext, useEffect, useMemo, useState } from 'react';
import * as serviceWorker from '../../../serviceWorkerRegistration'

type ServiceWorkerType = {
  currentServiceWorkerRegistration?: ServiceWorkerRegistration
  isUpdateAvailable?: boolean
  updateAssets?: () => void
}

const ServiceWorkerContext = React.createContext({});
export const useServiceWorker = () => useContext<ServiceWorkerType>(ServiceWorkerContext);

type PropTypes = {
  children: React.ReactNode
}

export const ServiceWorkerProvider = ({ children }: PropTypes) => {
  const [waitingServiceWorker, setWaitingServiceWorker] = useState<ServiceWorker | null>(null);
  const [currentServiceWorkerRegistration, setCurrentServiceWorkerRegistration] = useState<ServiceWorkerRegistration | null>(null);
  const [isUpdateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    serviceWorker.register({
      onUpdate: registration => {
        setWaitingServiceWorker(registration.waiting);
        setUpdateAvailable(true);
      },
      onWaiting: waiting => {
        setWaitingServiceWorker(waiting);
        setUpdateAvailable(true);
      },
      returnCurrentSw: registration => {
        setCurrentServiceWorkerRegistration(registration)
      }
    });
  }, []);

  useEffect(() => {
    navigator.serviceWorker.addEventListener('message', event => {
      const { action, data } = event.data;
      // Hier kannst du die Daten verarbeiten, die du vom Service Worker erhalten hast
      console.log(action, data);
    });
  }, []);

  useEffect(() => {
    // We setup an event listener to automatically reload the page
    // after the Service Worker has been updated, this will trigger
    // on all the open tabs of our application, so that we don't leave
    // any tab in an incosistent state
    if (waitingServiceWorker !== null) {
      waitingServiceWorker.addEventListener('statechange', event => {
        const sw = event?.target as ServiceWorker;
        if (sw.state === 'activated') {
          window.location.replace('/dashboard')
        }
      });
    }
  }, [waitingServiceWorker]);

  const value = useMemo(() => ({
    isUpdateAvailable,
    currentServiceWorkerRegistration,
    updateAssets: () => {
      if (waitingServiceWorker) {
        // We send the SKIP_WAITING message to tell the Service Worker
        // to update its cache and flush the old one
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    }
  }), [currentServiceWorkerRegistration, isUpdateAvailable, waitingServiceWorker]);

  return (
    <ServiceWorkerContext.Provider value={value}>
      {children}
    </ServiceWorkerContext.Provider>
  );
}
